<section>
    <form
      fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"
      [formGroup]="varForm" (ngSubmit)="onSubmit()">

      <mat-form-field>
        <mat-label>Amount</mat-label>
        <span matPrefix>$&nbsp;</span>
        <input matInput placeholder="0.00" formControlName="amount" type="number">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Date</mat-label>
        <input matInput formControlName="date" [matDatepicker]="myDatepicker">
        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Vendor</mat-label>
        <input matInput formControlName="vendor" type="text">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Budget item</mat-label>
        <input matInput formControlName="budget" type="text">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Account paid from</mat-label>
        <input matInput formControlName="account" type="text">
      </mat-form-field>
      <button type="submit" mat-raised-button color="primary" [disabled]="!varForm.valid">Add Transaction</button>
    </form>


<!--
  <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" #formData="ngForm" (ngSubmit)="onSubmit(formData)">
    <h3>Transaction</h3>
    <mat-form-field>
      <mat-label>Amount</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input matInput placeholder="Amount$$" ngModel name="amount" type="number" required #amountInput="ngModel">
      <mat-error *ngIf="amountInput.hasError('required')">Amount cannot be empty</mat-error>
      <mat-error *ngIf="amountInput.hasError('number')">Amount is not a valid number</mat-error>
      <mat-error *ngIf="!amountInput.hasError('required')">??</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Transaction date</mat-label>
      <input matInput placeholder="Transaction Date" ngModel name="dateInput" type="date" required #dateInput="ngModel"> -->
      <!-- <mat-label>Transaction date</mat-label>
      <input matInput [matDatepicker]="picker" ngModel name="date" #dateInput="ngModel">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker> -->
      <!-- <input matInput placeholder="Date" ngModel name="date" type="date" required #dateInput="ngModel">
      <mat-error *ngIf="dateInput.hasError('required')">Date cannot be empty</mat-error> -->
    <!-- </mat-form-field> -->
    <!-- <mat-form-field>
      <input matInput type="password" placeholder="Password" ngModel name="password" required #pwdInput="ngModel">
      <mat-error *ngIf="pwdInput.hasError('required')">Password is required</mat-error>
    </mat-form-field> -->
    <!-- <button type="submit" mat-raised-button color="primary" [disabled]="formData.invalid">Login</button>
  </form> -->
</section>
