import { NgModule } from '@angular/core';

import { HomeComponent } from './public/home/home.component';
import { DashboardComponent } from './private/dashboard/dashboard.component';

import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'common/guards/auth.guard';
import { TransactionComponent } from './private/transaction/transaction.component';


const appRoutes : Routes = [
  { path: '', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent,  canActivate: [AuthGuard]  },
  { path: 'transaction', component: TransactionComponent,  canActivate: [AuthGuard]  }
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
