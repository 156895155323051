import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatCardModule } from '@angular/material/card';


@NgModule({
  imports: [CommonModule, MatToolbarModule, MatButtonModule, MatChipsModule, MatDatepickerModule, MatNativeDateModule, MatListModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatTableModule, MatTooltipModule, MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule, MatIconModule, MatGridListModule, MatBadgeModule ],
  exports: [CommonModule, MatToolbarModule, MatButtonModule, MatChipsModule, MatDatepickerModule, MatNativeDateModule, MatListModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatTableModule, MatTooltipModule, MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule, MatIconModule, MatGridListModule, MatBadgeModule ],
  providers: [MatDatepickerModule]
})
export class MaterialModule {

}
