<div class="sidenav_container"  [@onSideNavChange]="sideNavState ? 'open' : 'close'">
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">

    <div class="user_menu">
      <mat-nav-list>
        <a mat-list-item>
          <img class="user_picture" src="{{userPhotoURL}}" alt="">
          <span style="padding-left:5px;" [@animateText]="showLinkText ? 'show' : 'hide'"> {{ userDisplayName }}</span>
        </a>
      </mat-nav-list>
      <mat-divider></mat-divider>
    </div>

    <div>
      <mat-nav-list>
        <a mat-list-item *ngFor="let page of pages" routerLink="{{page?.link}}">
          <mat-icon style="padding-right:5px;">{{page?.icon}}</mat-icon>
          <span [@animateText]="showLinkText ? 'show' : 'hide'">{{ page?.name }} </span>
        </a>
      </mat-nav-list>
    </div>
  </div>

  <span class="spacer"></span>
  <div fxLayout="row" fxLayoutAlign="end center" style="padding: 0px 10px;">
    <button mat-icon-button (click)="onSinenavToggle()">
      <mat-icon *ngIf="sideNavState">arrow_left</mat-icon>
      <mat-icon *ngIf="!sideNavState">arrow_right</mat-icon>
    </button>
  </div>
</div>
