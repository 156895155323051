import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'common/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

   constructor(public auth: AuthService, private router : Router) {

   }

  ngOnInit() {
  }

  googleSignin() {
    this.auth.googleSignin();
  }

  displayText() {
    if (this.auth.isUserLoggedIn()) {
      if(this.auth.userData != null)
        return this.auth.userData.displayName;
      else
        return "user name unresolved";
    }
    else {
      return 'No user logged in';
    }
  }

  async logout() {
    await this.auth.signOut();
  }

}
