import { Injectable } from "@angular/core";

import { User } from "common/model/user.model";
import { DataService } from "../data.service";
import { modelDataPaths } from "common/model/model.constants";

@Injectable({providedIn:'root'})
export class UserDataService extends DataService<User>
{
  protected dataPath : string = modelDataPaths.users;
}
