<mat-toolbar color="primary">
  <span *ngIf="auth.user$ | async; then authenticated else guest">
    loading...
  </span>

  <!-- User NOT logged in -->
  <ng-template #guest>
    <span><!-- <a routerLink="/">Finny</a> --></span>
    <span class="app-fill">Finny</span>
    <span>
      <!-- <button mat-raised-button (click)="googleSignin()">Sign in with Google</button> -->
    </span>
  </ng-template>


  <!-- User logged in -->
  <ng-template #authenticated>
    <span><a routerLink="/">Finny</a></span>

  <span class="app-fill"></span>
  <span>
    <div *ngIf="auth.user$ | async as user">
      <button mat-raised-button (click)="logout()">Logout</button>
    </div>
  </span>
  </ng-template>

</mat-toolbar>

