import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'common/model/user.model';

import * as firebase from 'firebase/app';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserDataService } from './data/user.data.service';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAnalytics } from "@angular/fire/analytics";


@Injectable({ providedIn: 'root' })
export class AuthService {

    user$: Observable<User>;
    userData : User;
    private isInitialized : boolean = false;

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private userDS: UserDataService,
        private afAnalytics: AngularFireAnalytics
    ) {

      // Get the auth state, then fetch the Firestore user document or return null
      this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
            // Logged in
          if (user) {
            this.userData = this.getUserData(user);
            if(!this.isInitialized)
            {
              console.log('auth log in for ' + this.userData.displayName);
              this.isInitialized = true;
              afAnalytics.logEvent('login');
            }
            return this.userDS.item$(user.uid);
          } else {
            // Logged out
            console.log('auth is NOT logged in');
            this.isInitialized = false;
            return of(null);
          }
        })
      );
    }

    async googleSignin() {
      const provider = new firebase.default.auth.GoogleAuthProvider();
      const credential = await this.afAuth.signInWithPopup(provider);

      this.isInitialized = true;

      return this.updateUserData(credential.user);
    }

    private getUserData(user: firebase.default.User) : User{

       const data = {
         id: user.uid,
         email: user.email,
         displayName: user.displayName,
         photoURL: user.photoURL,
         phone: user.phoneNumber
       };

       return data;
    }
    private updateUserData(user: firebase.default.User) {
      this.userData = this.getUserData(user);
    }

    isUserLoggedIn()
    {
      return this.afAuth.authState !== null;
    }

    async signOut() {
      await this.afAuth.signOut();

      this.userData = null;
      this.isInitialized = false;

      this.router.navigate(['/']);
    }

}
