
<mat-card>
  <mat-card-header matBadge='{{ badgeContent() }}' [matBadgeHidden]="level === 0" matBadgeColor="primary">
    <mat-icon mat-card-avatar *ngIf="level === 0">lock</mat-icon>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle>{{ content }} </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content></mat-card-content>
  <mat-card-actions>
      <button mat-button color="primary" *ngIf="level > 0">Start</button>
  </mat-card-actions>
</mat-card>
