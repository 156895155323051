import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AngularFirestore, QueryFn } from "@angular/fire/firestore";

import { IStorable } from "common/model/IStorable";


@Injectable({ providedIn: 'root' })
export abstract class DataService<T extends IStorable>
{
  protected dataPath : string;

  constructor( private afs: AngularFirestore )
  {

  }

  item$(id : string) : Observable<T>
  {
    return this.afs.doc<T>(this.dataPath + '/' + id).valueChanges();
  }

  list$(queryFn? : QueryFn) : Observable<T[]>
  {
    return this.afs.collection<T>(this.dataPath, queryFn).valueChanges();
  }

  update (item : T)
  {
    return this.afs.doc(this.dataPath + '/' + item.id).set(item, {merge : true});
  }

  createId()
  {
    return this.afs.createId();
  }

}
