import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  varForm: FormGroup;

  ngOnInit() {
    this.varForm = new FormGroup({
      'amount': new FormControl(null),
      'date': new FormControl(null),
      'vendor': new FormControl(null),
      'budget': new FormControl(null),
      'account': new FormControl(null)
    })
  }

  onSubmit(){

  }
}
