<mat-grid-list class="dashboard-cards" cols="2" rowHeight="2:1">
  <mat-grid-tile>
    <app-dashcard [title]="'Income'" [content]="'Start with...'" [level]=1></app-dashcard>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-dashcard [title]="'Spending'" [content]="'Start with...'" [level]=1></app-dashcard>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-dashcard [title]="'Income Allocation'" [content]="'Requires Income Level 1'"></app-dashcard>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-dashcard [title]="'Budget'" [content]="'Requires Spending Level 1'"></app-dashcard>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-dashcard [title]="'Retirement Plan'" [content]="'Requires [TBD]'"></app-dashcard>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-dashcard [title]="'Debt Progress'" [content]="'Requires [TBD]'"></app-dashcard>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-dashcard [title]="'Net Worth'" [content]="'Requires [TBD]'"></app-dashcard>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-dashcard [title]="'Cashflow'" [content]="'Requires [TBD]'"></app-dashcard>
  </mat-grid-tile>
</mat-grid-list>
