import { Component, Input, OnInit } from '@angular/core';
import { animateText, onSideNavChange } from 'common/animations/sideNav.animation';
import { Page } from 'common/model/navigation/navPage.model';
import { AuthService } from 'common/services/auth.service';
import { SideNavService } from 'common/services/navigation/sideNav.service';



@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css'],
  animations: [onSideNavChange, animateText]
})
export class LeftMenuComponent implements OnInit {

  public sideNavState: boolean = true;
  public showLinkText: boolean = true;
  public userPhotoURL: string;
  public userDisplayName: string;

  @Input() public pages: Page[];

  constructor(private _sidenavService: SideNavService, private auth: AuthService) {

   }

  ngOnInit() {
    this.userPhotoURL = this.auth.userData.photoURL;
    this.userDisplayName = this.auth.userData.displayName;
  }

  onSinenavToggle() {
    this.sideNavState = !this.sideNavState

    setTimeout(() => {
      this.showLinkText = this.sideNavState;
    }, 200)

    this._sidenavService.sideNavState$.next(this.sideNavState)
  }

}
